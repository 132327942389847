<template>
  <div class="input-group date">
    <div class="input-group-addon btn btn-sm">
      <i class="fa fa-calendar"></i>
    </div>
    <input
      v-bind="$attrs"
      class="form-control"
      ref="input"
      autocomplete="off"
    />
    <div
      class="input-group-addon btn btn-sm"
      v-if="datetime"
      @click.stop.prevent=""
      :title="$t('remove')"
    >
      <i class="fa fa-close"></i>
    </div>
  </div>
</template>

<script>
import messages from "@/i18n/datetime";

export default {
  name: "DatetimePicker",
  inheritAttrs: false,
  i18n: { messages },
  props: {
    value: String,
    format: {
      required: false,
      type: String,
      default: "dd/mm/yyyy hh:ii:ss"
    }
  },
  data() {
    return {
      datetime: null
    };
  },
  // watch: {
  //   datetime(newValue) {
  //     this.$emit("input", this.datetime?.format?.() ?? "");
  //   }
  // },
  methods: {
    clearDate() {
      this.$refs.input.value = "";
      this.datetime = null;
      // updates datetimepicker with current input value
      $(this.$el).datetimepicker("update");
    }
    // onChange() {
    //   $(this.$el).datetimepicker("update");
    // }
  },
  created() {
    $.fn.datetimepicker.dates["en"].daysMin = [
      this.$t("calendar.Su"),
      this.$t("calendar.Mo"),
      this.$t("calendar.Tu"),
      this.$t("calendar.We"),
      this.$t("calendar.Th"),
      this.$t("calendar.Fr"),
      this.$t("calendar.Sa"),
      this.$t("calendar.Su")
    ];
    $.fn.datetimepicker.dates["en"].months = [
      this.$t("calendar.January"),
      this.$t("calendar.February"),
      this.$t("calendar.March"),
      this.$t("calendar.April"),
      this.$t("calendar.May"),
      this.$t("calendar.June"),
      this.$t("calendar.July"),
      this.$t("calendar.August"),
      this.$t("calendar.September"),
      this.$t("calendar.October"),
      this.$t("calendar.November"),
      this.$t("calendar.December")
    ];
  },
  mounted() {
    $(this.$el)
      .datetimepicker({ format: this.format })
      .on("changeDate", (e) => {
        // let dt = new Date(e.date); // clone it
        // e.date.setSeconds(0);
        this.datetime = moment(e.date);
        // if (this?.$refs?.input) {
        //   this.$refs.input.value = this.$refs.input.value.replace(
        //     /\d{2}$/,
        //     "00"
        //   );
        // }

        // console.log(this.datetime?.format?.() ?? "");
      })
      .change((e) => {
        let value = $(this.$el).data("datetimepicker").getDate();
        // console.log(value);
        this.$emit("input", moment(value)?.format?.() ?? "");
      });
  },
  beforeDestroy() {
    $(this.$el).datetimepicker().remove();
  }
};
</script>

<style scoped>
input:read-only {
  background-color: white;
}
</style>
