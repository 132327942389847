<template>
  <section>
    <div class="row">
      <div class="col-md-12">
        <div class="create-box">
          <div v-if="$can('manage', 'TokenAcessoRemotoEscrita')">
            <span>{{ $t("create_new_token") }} </span>
            <div class="btn-group">
              <span
                class="btn btn-default btn-xs create-btn"
                v-for="(item, index) in tokenTypeList"
                v-bind:key="index"
                v-on:click.stop.prevent="create(item.id)"
                :data-content="
                  $parent.$parent.$t(`hints.${item.type.toLowerCase()}`)
                "
                data-toggle="popover"
              >
                <span>{{ item.type }} </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <SearchableTable
          class="searchable-table"
          v-if="items"
          :searchEnabled="false"
          :items="items"
          :fields="fields"
          :commands="commands"
          @command="onCommand"
        >
          <template v-slot:toggle="entry">
            <span @click.stop.prevent="toggleItem(entry.item)">
              {{ $t(entry.item.enabled ? "yes" : "no") }}
              <i
                :class="
                  busy
                    ? 'fa fa-refresh fa-spin'
                    : entry.item.enabled
                    ? 'fa fa-toggle-on text-green'
                    : 'fa fa-toggle-off'
                "
              ></i>
            </span>
          </template>
        </SearchableTable>
      </div>
    </div>
  </section>
</template>

<script>
import TokenService from "@/services/token.js";
import SearchableTable from "@/components/searchable-table.vue";

const initialState = () => {
  return {
    formData: {
      type_id: "",
      enabled: true,
      remote_access_token_type_id: ""
    },
    id: "",
    etag: "",
    busy: false,
    items: null
  };
};

export default {
  name: "FormConnectorPrivateToken",
  components: {
    SearchableTable
  },
  props: {
    connector_id: {
      type: Number,
      required: false,
      default: 3
    }
  },
  data() {
    return initialState();
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  computed: {
    fields() {
      return [
        {
          name: "token",
          title: "token"
        },
        {
          name: "type",
          title: "type",
          parser: function(item) {
            return (
              item.remote_access_token_type &&
              item.remote_access_token_type.name
            );
          }
        },
        {
          name: "status",
          title: "status",
          parser: (item) => {
            return item.status ? this.$t("active") : this.$t("inactive");
          }
        }
        // {
        //   name: "toggle",
        //   title: "enabled",
        //   style: {
        //     "text-align": "center"
        //   }
        // }
      ];
    },
    commands() {
      if (this.$can("manage", "TokenAcessoRemotoEscrita")) {
        return [
          {
            name: "toggleItem",
            title: (item) => {
              return this.$tc(item.enabled ? "enabled" : "disabled", 1);
            },
            icon: (item) => {
              return item.enabled
                ? "fa fa-toggle-on text-green"
                : "fa fa-toggle-off";
            }
          },
          { name: "copy", title: "copy", icon: "fa fa-copy" },
          { name: "remove", title: "remove", icon: "fa fa-trash" }
        ];
      }
      return [];
    },
    tokenTypeList() {
      return (
        (this.$root &&
          this.$root.config &&
          this.$root.config.references &&
          this.$root.config.references.remote_access_token_types) ||
        []
      );
    },
    payload() {
      let payload = {
        connector_id: this.connector_id,
        remote_access_token_type_id: this.formData.remote_access_token_type_id,
        enabled: this.formData.enabled
      };
      if (this.id) {
        payload.id = this.id;
      }
      if (this.etag) {
        payload.etag = this.etag;
      }
      return payload;
    },
    isValid() {
      return true;
    }
  },
  methods: {
    reset() {
      let items = JSON.parse(JSON.stringify(this.items));
      Object.assign(this.$data, initialState());
      this.items = items;
    },
    save() {
      if (!this.isValid) return;
      if (this.busy) return;
      this.busy = true;
      this.srv.save(this.payload).then((response) => {
        if (response) {
          this.reset();
          this.fetchTokens();
        }
      });
    },
    create(remote_access_token_type_id) {
      this.reset();
      this.formData.remote_access_token_type_id = remote_access_token_type_id;
      this.save();
    },
    remove(item) {
      if (this.busy) return;
      this.busy = true;
      this.srv.remove(item).then(() => {
        this.reset();
        this.fetchTokens();
      });
    },
    copy(item) {
      this.$utils.toClipboard(item.token || "");
    },
    toggleItem(item) {
      for (var p in this.formData) {
        this.formData[p] = item[p];
      }
      this.formData.enabled = !this.formData.enabled;
      this.etag = item.etag;
      this.id = item.id;
      this.save();
    },
    onCommand(command) {
      if (command.name in this) {
        this[command.name](command.target);
      }
    },
    fetchTokens() {
      let query = { connector_id: this.connector_id };
      this.srv.fetch(query).then((response) => {
        if (response && response.length) {
          this.$set(this, "items", response);
        } else {
          this.$set(this, "items", null);
        }
        this.$nextTick(() => {
          this.busy = false;
        });
      });
    }
  },
  mounted() {
    $(this.$el)
      .find("[data-toggle=popover]")
      .popover({
        trigger: "hover",
        placement: "auto top",
        html: true,
        delay: { show: 300 }
      });
  },
  created() {
    this.srv = new TokenService("private");
    this.fetchTokens();
  }
};
</script>

<style scoped>
.create-box {
  position: relative;
  margin: 10px 0;
}
.create-label {
  margin: 0 5px 0 0;
  font-size: 14px;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #636363;
}
.create-btn {
  margin-right: 15px;
}

.overlay-local {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 24pt;
  text-align: center;
  margin-top: 5%;
  color: #607d8b;
  width: 100%;
  z-index: 1;
}
.searchable-table {
  overflow-x: auto;
}
</style>
