<template>
  <section>
    <div data-testid="form-connector">
      <div>
        <form
          v-on:submit.prevent
          class="form"
          role="form"
          v-if="ready"
          ref="form"
        >
          <FormCommonFieldsInline v-model="iEquipment" />
          <div class="nav-tabs-custom">
            <ul class="nav nav-tabs">
              <li
                v-for="(tab, ix) in formTabs"
                :key="ix"
                :class="{ active: tab.id == activeTab }"
                @click.stop.prevent="activeTab = tab.id"
                :data-testid="tab.id"
              >
                <span
                  data-toggle="tooltip"
                  class="badge badge-tab"
                  v-if="tab.error"
                  :title="$t(tab.error)"
                >
                  <i class="fa fa-asterisk"></i>
                </span>
                <a :href="`#${tab.id}`" data-toggle="tab">
                  <Tooltip
                    icon=""
                    :title="$t(`tab_label.${tab.id}`)"
                    @click="activeTab = tab.id"
                  >
                    <i :class="tab.icon"></i>
                    <span class="hidden-sm hidden-xs">
                      {{ $tc(tab.title, 1).toUpperCase() }}
                    </span>
                  </Tooltip>
                </a>
              </li>
            </ul>
            <div class="tab-content" v-if="initialized">
              <div class="table-title">
                <div class="row">
                  <div class="col-xs-12 label">
                    {{ $t("tab_label." + activeTab) }}
                  </div>
                </div>
              </div>
              <!-- begin connector home -->
              <div
                class="tab-pane"
                id="tab_connector_home"
                :class="{ active: activeTab == 'tab_connector_home' }"
              >
                <FormInlineModel
                  v-if="allowModel"
                  v-model="connectorModel"
                  v-bind:connector_id="iEquipment.id || 0"
                  v-bind:disabled="
                    isOutOfQuota && (!equipment_id || connectorModel.is_model)
                  "
                  class="form-inline-model"
                />

                <!-- begin equipment type -->
                <div
                  v-bind:class="{ protected: cloneEnabled }"
                  v-if="input_equipment_type"
                >
                  <div
                    v-if="cloneEnabled"
                    class="protected-disabled-area"
                  ></div>
                  <FormEquipmentType
                    v-model="input_equipment_type"
                    :value="input_equipment_type"
                    :connectorModel="connectorModel"
                    :connector="iEquipment"
                    style="margin-top: 20px"
                  />
                  <div
                    v-if="false && isMQTT"
                    style="background-color: whitesmoke"
                  >
                    <div style="margin-left: 5px">
                      <label for=""> {{ $t("mqtt_parameters") }} </label>
                    </div>
                    <div class="row" style="margin: 0">
                      <div class="col-md-5 col-sm-6 form-group">
                        <div class="input-group">
                          <div class="input-group-addon">
                            {{ $t("disconnection") }}
                          </div>
                          <select
                            class="form-control"
                            style="padding-left: 0"
                            v-model="iEquipment.mqtt_disconnect_mode_id"
                          >
                            <option
                              v-for="(item, ix) in mqtt_disconnect_modes"
                              :key="ix"
                              :value="item.id"
                              :title="item.description"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                          <div
                            class="input-group-btn"
                            v-if="iEquipment.mqtt_disconnect_mode_id == 2"
                          >
                            <button
                              type="button"
                              class="btn dropdown-toggle"
                              data-toggle="dropdown"
                              aria-expanded="true"
                            >
                              {{ iEquipment.mqtt_disconnect_time }}min
                              <span class="fa fa-caret-down"></span>
                            </button>
                            <ul class="dropdown-menu">
                              <li
                                v-for="(item, ix) in mqtt_disconnect_times"
                                :key="ix"
                              >
                                <a
                                  href="#"
                                  @click="
                                    iEquipment.mqtt_disconnect_time = item
                                  "
                                >
                                  {{ item }}min
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end equipment type -->

                <!-- begin serial number / aquisition delay / screenId-->
                <div class="row">
                  <div
                    class="col-md-4 col-sm-6 col-xs-12"
                    v-if="!connectorModel.is_model"
                  >
                    <div class="form-group">
                      <label for="equipment_serial_number">
                        {{ $t("serial_number") }} ({{ $t("plc") }})
                        <Tooltip :title="$t('hints.serial_number')" />
                      </label>
                      <span
                        class="pull-right small clicable no-select"
                        :class="failover ? 'text-danger' : ''"
                        style="padding-top: 2px"
                        v-if="!isMQTT && this.$root.config.connector_failover"
                        @click.stop.prevent="failover = !failover"
                      >
                        {{ $t("secondary") }}
                        <Tooltip :title="$t('hints.serial_number_failover')" />
                      </span>
                      <div class="input-group">
                        <input
                          type="text"
                          :required="!isMQTT"
                          data-testid="serial-number"
                          :pattern="
                            isMQTT ? '.*' : '[a-zA-Z0-9]{0,4}\\.\\d{1,5}'
                          "
                          name="equipment_serial_number"
                          class="form-control text-center"
                          ref="serial_number"
                          v-model="iEquipment.serial_number"
                        />
                        <div
                          class="input-group-addon"
                          :title="
                            $t(
                              isSerialNumberValid(iEquipment.serial_number)
                                ? ''
                                : 'invalid_serial_number'
                            )
                          "
                        >
                          <i
                            class="fa fa-check"
                            v-if="isSerialNumberValid(iEquipment.serial_number)"
                          ></i>
                          <i
                            class="fa fa-exclamation-triangle text-warning"
                            v-else
                          ></i>
                        </div>
                      </div>
                      <div class="input-group" v-if="failover">
                        <div class="input-group-addon text-danger">
                          <span class="text-danger">
                            {{ $t("serial_number_failover") }}
                            <Tooltip
                              :title="$t('hints.serial_number_secondary')"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          :required="!isMQTT"
                          data-testid="serial-number"
                          pattern="[a-zA-Z0-9]{0,4}\.\d{1,5}"
                          name="equipment_serial_number"
                          class="form-control text-center"
                          ref="secondary_serial_number"
                          v-model="iEquipment.secondary_serial_number"
                        />
                        <div
                          class="input-group-addon"
                          :title="
                            $t(
                              isSerialNumberValid(
                                iEquipment.secondary_serial_number
                              )
                                ? ''
                                : 'invalid_serial_number'
                            )
                          "
                        >
                          <i
                            class="fa fa-check"
                            v-if="
                              isSerialNumberValid(
                                iEquipment.secondary_serial_number
                              )
                            "
                          ></i>
                          <i
                            class="fa fa-exclamation-triangle text-warning"
                            v-else
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-sm-6 col-xs-12" v-if="!isMQTT">
                    <PoolingIntervalSelector
                      v-model="iEquipment.pooling_interval"
                      title="acquisition_interval"
                      label="acquisition_interval"
                      tooltip="hints.acquisition_interval"
                    />
                  </div>
                  <div
                    class="col-md-4 col-sm-6 col-xs-12"
                    v-show="screenSelection"
                  >
                    <div class="form-group">
                      <label for>
                        {{ $tc("screen", 1) }}
                        <Tooltip :title="$t('hints.screen_selection')" />
                      </label>
                      <ScreenSelector
                        v-model="iEquipment.screen_id"
                        class="screen-selector"
                      >
                        <template #addonAfter>
                          <div
                            v-if="canEditScreen"
                            class="input-group-addon btn"
                            :class="{ 'addon-link': canEditScreen }"
                            @click.stop.prevent="onEditScreen('edit')"
                            :title="$t('edit')"
                          >
                            <i class="fa fa-pencil"></i>
                          </div>
                          <div
                            class="input-group-addon btn"
                            :class="{ 'addon-link': canEditScreen }"
                            @click.stop.prevent="onEditScreen('new')"
                            :title="$tc('new', 2)"
                          >
                            <i class="fa fa-plus"></i>
                          </div>
                        </template>
                      </ScreenSelector>
                    </div>
                  </div>
                </div>
                <!-- end serial number -->

                <!-- begin process area / timezone / location-->
                <div class="row process-area" v-if="process_area">
                  <div class="col-lg-4 col-sm-6">
                    <ProcessAreaSelection
                      v-bind:value="process_area"
                      v-model="process_area"
                      @processAreaRemoved="onProcessAreaRemoved"
                    />
                  </div>
                  <div class="col-lg-4 col-sm-6">
                    <TimeZoneSelectionForm
                      :addon="''"
                      v-bind:value="iEquipment.timezone"
                      v-model="iEquipment.timezone"
                    />
                  </div>
                  <div
                    class="col-lg-4 col-sm-12"
                    v-if="!connectorModel.is_model"
                  >
                    <div class="form-group">
                      <label for="location">
                        {{ $t("location") }}
                        <Tooltip :title="$t('hints.location')" />
                      </label>
                      <input
                        type="text"
                        data-testid="location-name"
                        name="location"
                        class="form-control"
                        v-bind:placeholder="$t('location_placeholder')"
                        v-model="iEquipment.location_name"
                      />
                    </div>
                  </div>
                </div>
                <!-- end process area -->

                <!-- begin  device -->
                <div
                  class="basic-device-box"
                  v-if="!equipment_id && !connectorModel.model"
                >
                  <div class="form-group">
                    <label class="checkbox-inline" v-if="!forceStandardDevice">
                      <input
                        type="checkbox"
                        v-model="create_standard_device"
                        data-testid="create-standard-device"
                        id
                      />
                      {{ $t("create_standard_device") }}
                    </label>
                  </div>
                  <DeviceEditorBasicForm
                    v-if="create_standard_device"
                    :label="forceStandardDevice ? 'standard_device' : ''"
                    :value="basicDevice"
                    :connector="iEquipment"
                    v-model="basicDevice"
                  />
                </div>
                <div
                  v-else-if="connectorModel.model && connectorModel.model.id"
                  style="margin: -15px -10px 0 -10px"
                >
                  <DeviceSelector
                    v-model="$parent.$data.selectedDeviceIds"
                    :connector="iEquipment"
                    @update:preserveReferencyId="
                      $parent.$data.preserveReferencyId = $event
                    "
                    @update:forecastData="$parent.$data.forecastData = $event"
                    :forceDataListRequest="true"
                    :disabled="busy"
                    :forecast="
                      cloneEnabled || !this.equipment_id
                        ? 'always'
                        : 'if_changed'
                    "
                    :key="connectorModel.model.id"
                  >
                    <template #title>
                      <div style="margin-top: 20px">
                        <label for=""
                          >{{
                            $t("titles.available_list", {
                              list: $tc("device", 2)
                            })
                          }}
                          <Tooltip :title="$t('hints.device_selection')"
                        /></label>
                      </div>
                    </template>
                  </DeviceSelector>
                  <div class="text-center small" style="margin: -20px 0 20px 0">
                    <em>*</em>
                    {{
                      $t("titles.consume_forecast", {
                        devices: $parent.$data.selectedDeviceIds.length,
                        data: $parent.$data.forecastData
                      })
                    }}
                  </div>
                </div>
                <!-- end  device -->
              </div>
              <!-- end connector home -->

              <!-- begin connector address -->
              <div
                class="tab-pane"
                id="tab_connector_address"
                :class="{ active: activeTab == 'tab_connector_address' }"
              >
                <AddressInputForm
                  v-if="!connectorModel.is_model"
                  style="margin-top: 20px"
                  v-bind:value="iEquipment.location_address"
                  v-model="iEquipment.location_address"
                />
              </div>
              <!-- end connector address -->

              <!-- begin connector tokens -->
              <div
                class="tab-pane"
                id="tab_connector_keys"
                :class="{ active: activeTab == 'tab_connector_keys' }"
              >
                <FormConnectorTokens
                  v-if="
                    iEquipment.id &&
                    !connectorModel.is_model &&
                    contract &&
                    contract.allowed_remote_access &&
                    $can('manage', 'TokenAcessoRemotoAcesso')
                  "
                  v-bind:connector="iEquipment"
                  @loading="loading"
                />
              </div>
              <!-- end connector tokens -->

              <!-- begin notification -->
              <div
                class="tab-pane"
                id="tab_connector_notification"
                :class="{ active: activeTab == 'tab_connector_notification' }"
              >
                <FormConfigNotification
                  v-model="groups"
                  style="margin-top: 2rem"
                  :enabled="iEquipment.notification_enabled"
                  @enable="iEquipment.notification_enabled = true"
                  @disable="iEquipment.notification_enabled = false"
                />
              </div>
              <!-- end notification -->

              <!-- begin data configuration -->
              <div
                class="tab-pane"
                id="tab_data_list"
                :class="{ active: activeTab == 'tab_data_list' }"
              >
                <DataOrderConfiguration
                  v-if="equipment_id || connectorModel.model"
                  class="center-block"
                  v-model="(iEquipment.portal_data || {}).custom_view"
                  :connectorId="equipment_id || connectorModel.model.id"
                />
                <DataOrderConfiguration
                  v-if="equipment_id || connectorModel.model"
                  class="center-block"
                  v-model="
                    (iEquipment.portal_data || {}).custom_history_data_list
                  "
                  :connectorId="equipment_id || connectorModel.model.id"
                  :labels="{
                    box_title: 'customize_history_view',
                    box_tooltip: 'hints.custom_view_history',
                    btn_redefine: 'hints.redefine_custom_view'
                  }"
                  :dataFilter="filterHistoryEnabledData"
                  :multiSelection="true"
                />
                <div class="">
                  <div class="pull-left">
                    <ClearHistory target="connector" :targetId="equipment_id" />
                  </div>
                  <div style="clear: both" />
                </div>
              </div>
              <!-- end data configuration -->

              <!-- begin data mapping -->
              <div
                class="tab-pane"
                id="tab_connector_data_mirroring"
                v-if="isDataMappingEnabled"
                :class="{ active: activeTab == 'tab_connector_data_mirroring' }"
              >
                <DataMirroringTableMap :connectorId="equipment_id" />
              </div>
              <!-- end data mapping -->

              <!-- begin custom fields configuration -->
              <div
                class="tab-pane"
                id="tab_custom_fields"
                :class="{ active: activeTab == 'tab_custom_fields' }"
              >
                <FormExtendedProperties
                  class="extended-properties-panel fade in"
                  v-if="
                    this.modelConfiguration && this.modelConfiguration.enabled
                  "
                  v-model="modelExtendedProperties"
                  :restoreButton="true"
                  :resetButton="true"
                  :importButton="true"
                  @restore="onRestoreExtendedProperties"
                />
                <div v-else>
                  <!-- <iframe
                    class="scrollable-iframe fade in"
                    v-bind:src="custom_page.url"
                    v-on:load="onCustomPageReady"
                    v-show="custom_page.ready"
                  /> -->
                  <iframe
                    ref="custom_page"
                    class="scrollable-iframe fade in"
                    v-on:load="onCustomPageReady"
                    v-show="custom_page.ready && custom_page.url"
                  />
                </div>
              </div>
              <!-- end custom fields configuration -->
            </div>
          </div>
        </form>
      </div>
      <FormFooterToolbar
        v-if="ready"
        v-model="cloneEnabled"
        :remove="iEquipment.id ? true : false"
        :busy="busy"
        :valid="isValid"
        :clone="equipment_id != 0"
        :value="cloneEnabled"
        @buttonCancelClick="onButtonCancelClick"
        @buttonSaveClick="onButtonSaveClick"
        @buttonCloneClick="onButtonCloneClick"
        @buttonRemoveClick="onButtonRemoveClick"
        rule="EquipamentoEscrita"
      />
    </div>
    <!-- <DataMirroringTableMap :connectorId="equipment_id" /> -->
  </section>
</template>

<script>
// Components
import FormBase from "@/components/registration/form-base.vue";
import AddressInputForm from "@/components/registration/address-input-form.vue";
import ProcessAreaSelection from "@/components/processarea-selection.vue";
import FormEquipmentType from "@/components/registration/form-equipment-type.vue";
import DeviceEditorBasicForm from "@/components/registration/device-editor-basic-form.vue";
import FormConnectorTokens from "@/components/registration/form-connector-tokens.vue";
import FormCommonFieldsInline from "@/components/registration/form-common-fields-inline.vue";
import FormInlineModel from "@/components/registration/form-inline-model.vue";
import TimeZoneSelectionForm from "@/components/time-zone-selection-form.vue";
import MixinAlert from "@/project/mixin-alert.js";
import ConnectorService from "@/services/connector.js";
import FormConfigNotification from "@/components/registration/form-config-notification.vue";
import DataOrderConfiguration from "@/components/data/data-order-configuration.vue";
import ClearHistory from "@/components/history/clear-history";
import Tooltip from "@/components/tooltip.vue";
import PoolingIntervalSelector from "@/components/pooling-interval-selector.vue";
import DataMirroringTableMap from "@/components/registration/data-mirroring-table-map.vue" /* webpackChunkName: 'data-mirroring-table-map' */;
import FormExtendedProperties from "@/components/registration/form-extended-properties.vue";
import ScreenSelector from "@/components/editor/screen-selector.vue";
import DeviceSelector from "@/components/registration/device-selector.vue";
import { isMQTT, isSingleDevice } from "@/services/connector.js";
import { isEqual } from "lodash";
// Localization
import messages from "@/i18n/connector";
import { warnUnsavedChanged } from "@/project/mixin-alert.js";
function defaultData() {
  return {
    iEquipment: {
      id: "",
      name: "",
      enabled: true,
      location_name: "",
      location_address: {
        country: "",
        state: "",
        city: "",
        district: "",
        street: "",
        number: "",
        zipcode: "",
        latlng: "",
        extrainfo: ""
      },
      process_area: { id: 0 },
      connector_type: {
        id: "",
        name: ""
      },
      protocol: {
        id: "",
        name: ""
      },
      screen_id: "",
      process_area_id: "", // local models
      connector_type_id: "", // local models
      protocol_id: "", // local models
      serial_number: "",
      secondary_serial_number: "",
      timezone: "",
      updates_datetime: false,
      optimizes_frames: false,
      portal_data: {
        custom_view: {},
        custom_history_data_list: {},
        daily_summary_notifications: {},
        default_notifications: {}
      },
      user_data: null,
      mqtt_disconnect_mode_id: 1,
      mqtt_disconnect_time: 60,
      mqtt_topic_prefix: "",
      ignore_protocol_change: false
    },
    connectorModel: {
      is_model: false,
      propagate: false,
      model: undefined
      // keep_data_reference_id: false
    },
    initialized: false,
    basicDevice: {
      name: "",
      is_hi_device: true, // it seems it will changed
      device_address: "1",
      label: ""
    },
    locked: true,
    localSourceSelection: false,
    loaded: false,
    process_area: {
      inherits_parent_process_area: false,
      id: 0
    },
    input_equipment_type: {
      type_id: "",
      protocol_id: "",
      datetime_update: false,
      optmized: true
    },
    input_equipment_enabled: "1",
    backup: null,
    exitSuccess: false,
    create_standard_device: false,
    restoreOriginalBeforeClone: false,
    extended_properties: undefined,
    screens: [],
    failover: false,
    activeTab: "tab_connector_home",
    custom_page: {
      ready: false,
      url: ""
    },
    modelConfiguration: {
      enabled: false,
      extendedProperties: []
    }
  };
}

export default {
  name: "FormConnector",
  mixins: [MixinAlert],
  extends: FormBase,
  components: {
    ProcessAreaSelection,
    FormEquipmentType,
    AddressInputForm,
    TimeZoneSelectionForm,
    DeviceEditorBasicForm,
    FormConnectorTokens,
    FormConfigNotification,
    DataOrderConfiguration,
    ClearHistory,
    Tooltip,
    FormInlineModel,
    FormCommonFieldsInline,
    PoolingIntervalSelector,
    DataMirroringTableMap,
    FormExtendedProperties,
    ScreenSelector,
    DeviceSelector
  },
  props: {
    equipment_id: {
      type: Number,
      required: false,
      default: () => null
    },
    formActionRequest: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return defaultData();
  },
  watch: {
    busy(n) {
      this.loading(n);
    },
    input_equipment_type(n, o) {
      if (n) {
        this.iEquipment.connector_type.id = n.type_id;
        this.iEquipment.protocol.id = n.protocol_id;
        this.iEquipment.updates_datetime = n.datetime_update;
        this.iEquipment.optimizes_frames = n.optmized;

        if (o?.protocol_id && n.protocol_id != o.protocol_id) {
          this.$nextTick(() => {
            this.$set(this.iEquipment, 'ignore_protocol_change', this.hasIncompatibleMemoryTypes());
          });
        }
      }
    },
    cloneEnabled(n, o) {
      if (n) {
        let self = this;
        self.ready = false;
        self.$nextTick(function() {
          if (self.restoreOriginalBeforeClone && self.backup) {
            self.initData(self.backup);
          }
          self.iEquipment.serial_number = "";
          self.iEquipment.secondary_serial_number = "";
          self.iEquipment.name = "";
          self.ready = true;
          // self.$nextTick(function() {
          //   self.$refs.serial_number.focus();
          // });
        });
      }
    },
    connectorModel: {
      handler(n, o) {
        let connector = this.iEquipment;
        if (n && n.model && (!o || !o.model || o.model.id != n.model.id)) {
          // connector.name = n.model.name;
          // connector.description = n.model.description;
          connector.pooling_interval = n.model.pooling_interval * 1000;
          // process area
          let process_area = this.process_area;
          process_area.id = n.model.process_area.id;
          // hardware
          let input_equipment_type = this.input_equipment_type;
          input_equipment_type.datetime_update = n.model.updates_datetime;
          input_equipment_type.optmized = n.model.optimizes_frames;
          input_equipment_type.protocol_id = n.model.protocol.id;
          input_equipment_type.type_id = n.model.connector_type.id;
          // select screen
          connector.screen_id = "";
          this.initScreenSelection(connector);
          // portal data
          connector.portal_data = JSON.parse(
            JSON.stringify(n.model?.portal_data)
          );
          if (n.model?.portal_data?.daily_summary_notifications) {
            this.$set(this, "groups", null);
          }
          // commit
          connector.base_model_id = n.model.id;

          this.$set(this, "iEquipment", connector);
          // force update
          this.$set(this, "process_area", null);
          this.$set(this, "input_equipment_type", null);
          this.$nextTick(() => {
            this.$set(this, "process_area", process_area);
            this.$set(this, "input_equipment_type", input_equipment_type);
            this.initGroups(connector);
          });
        } else {
          // base model was removed
          if (n && !n.model && o && o.model) {
            this.$set(this, "groups", null);
            connector.portal_data.daily_summary_notifications = {};
            connector.portal_data.default_notifications = {};
            connector.base_model_id = null;
            this.$set(this, "iEquipment", connector);
            this.$nextTick(() => {
              this.initGroups(connector);
            });
          }
        }
        // this.$emit("modelChanged", n && n.is_model);
        this.onModelChanged(n && n.is_model);
        this.setupCustomPage(this.iEquipment);
      },
      deep: true
    },
    screenList() {
      this.initScreenSelection();
    },
    equipment_id(n, o) {
      if (o || n != o) {
        this.resetData();
        this.setup();
        this.initScreenSelection();
        this.$emit("connectorTypeChanged", this.iEquipment);
      }
    },
    formActionRequest(n) {
      if (n) {
        if (n == "save_exit") {
          if (this.isValid) {
            this.onButtonSaveClick(false);
          }
        } else if (n == "save_stay") {
          if (this.isValid) {
            this.onButtonSaveClick(true);
          }
        } else if (n == "save_add_new") {
          if (this.isValid) {
            this.onButtonSaveClick(true, true);
          }
        } else if (n == "remove") {
          this.onButtonRemoveClick();
        } else if (n == "cancel") {
          this.onButtonCancelClick();
        }
      }
      this.$emit("resetFormActionRequest");
    },
    failover(n, o) {
      if (n) {
        this.$nextTick(() => {
          this.$refs.secondary_serial_number.focus();
        });
      }
    },
    payload(n) {
      this.$emit("change", this.payload);
      if (n && typeof this.$parent.updateHash == "function") {
        this.$parent.updateHash(n);
      }
    }
  },
  computed: {
    screenSelection() {
      return (this.contract && this.contract.allowed_custom_screens) || false;
    },
    canEditScreen() {
      if (!this.screenSelection) return false;
      let screen = this.screenList.find(
        ({ id }) => id == this.iEquipment.screen_id
      );
      return screen && !screen.public;
    },
    allowModel() {
      return (
        (this.initialized &&
          this.contract &&
          this.contract.allowed_model_connectors) ||
        false
      );
    },
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        ({ id, deleted_at, portal_data }) => id > -1 && !deleted_at && (!portal_data || !portal_data.excluded_at)
      );
    },
    removalMessage() {
      let msg = "";
      let item = this.iEquipment;
      if (item.base_model) {
        let message = this.$t("removal_model_message");
        let text = this.$t("you_wont_be_able_to_revert_this");
        let field_name = this.$tc("connector");
        let value = item.name;
        let cls = "fa fa-exclamation-triangle";
        let warning = `<p>${message}<br/><div class="text-warning"><i class="${cls}"></i> ${text}</div></p>`;
        let html = `<b>${field_name}</b>: ${value}${warning}`;
        msg = this.wrap(html); // can be implemented at child level
      } else {
        msg = this.warningContent(
          "connector",
          item.name,
          "you_wont_be_able_to_revert_this"
        );
      }
      return msg;
    },
    poolingInterval() {
      return this.$root.config.pooling_interval &&
        this.$root.config.pooling_interval.value &&
        this.$root.config.pooling_interval.options &&
        this.$root.config.pooling_interval.options.length
        ? this.$root.config.pooling_interval
        : {
          value: 30000,
          options: [
            { value: 30000, label: "30s" },
            { value: 60000, label: "1min" },
            { value: 150000, label: "2.5 min" },
            { value: 300000, label: "5 min" },
            { value: 600000, label: "10 min" },
            { value: 900000, label: "15 min" }
          ]
        };
    },
    forceStandardDevice() {
      // if not configured - default is true
      return this.$root.config && "force_standard_device" in this.$root.config
        ? this.$root.config.force_standard_device
        : true;
    },
    isValid() {
      return (this.connectorModel.is_model || this.isMQTT
        ? true
        : this.iEquipment.serial_number) &&
        this.iEquipment.name &&
        this.iEquipment.connector_type.id &&
        (this.iEquipment.location_name || this.connectorModel.is_model) &&
        (this.connectorModel.is_model ? true : this.iEquipment.timezone) &&
        (this.create_standard_device &&
          !this.connectorModel.model &&
          !this.isMQTT
          ? this.basicDevice.name != "" && this.basicDevice.device_address != ""
          : true) &&
        this.isExtendedPropertiesValid
        ? true
        : false;
    },
    payload() {
      let payload = { ...defaultData().iEquipment, ...this.iEquipment };
      let user = this.$store.getters["user/loggedUser"];
      if (this.iEquipment.id) {
        payload.id = this.iEquipment.id;
      }

      // begin model management
      payload.base_model = this.connectorModel.is_model;
      payload.base_model_id =
        (this.connectorModel.model && this.connectorModel.model.id) || null;

      if (this.connectorModel.propagate) {
        payload.apply_changes_to_instances = true;
      }

      payload.pooling_interval = payload.pooling_interval / 1000;
      payload.connector_type_id = payload.connector_type.id;
      payload.protocol_id = payload.protocol.id;

      payload.process_area_id =
        (this.process_area && this.process_area.id) || "";
      payload.contract_id = (user && user.contract_id) || 1;
      if (this.create_standard_device) {
        payload.device = {
          name: this.basicDevice.name,
          address: this.basicDevice.device_address,
          is_hi_device: this.basicDevice.is_hi_device
        };
        if (this.isMQTT) {
          payload.device.label = this.basicDevice.label;
        }
      }

      payload.portal_data = {
        ...payload.portal_data,
        ...(this.payload_notifications || {})
      }; // parent with default notification

      // extended properties
      if (payload.base_model) {
        // from properties - it might be different of the declared in the contract
        payload.portal_data.extended_properties = this.modelExtendedProperties;
      } else {
        // remove any related model properties left by form changes on the portal_data
        delete payload.portal_data.extended_properties;
        // from custom webpage (iframe)
        if (this.extended_properties) {
          payload.user_data = payload.user_data || {};
          payload.user_data.extended_properties = this.extended_properties;
        }
      }

      if (payload.base_model_id) {
        payload.portal_data.model_device_ids = [
          ...this.$parent.$data.selectedDeviceIds
        ];
      } else {
        // ordinary connector / model
        // payload.portal_data.keep_data_reference_id = this.connectorModel.keep_data_reference_id;
      }

      if (!payload.timezone) {
        delete payload.timezone;
      }
      // local screen
      if (parseInt(payload.screen_id) > 999999999) {
        payload.screen_id = null;
      }

      delete payload.connector_type;
      delete payload.protocol;
      delete payload.process_area;
      delete payload.is_connected;
      delete payload.number_active_alarms;
      delete payload.has_active_alarms;
      return payload;
    },
    isOutOfQuota() {
      let self = this;
      return self.outOfQuota(
        this.contract.maximum_connectors,
        this.contract.registered_connectors
      );
    },
    isExtendedPropertiesValid() {
      return !this.connectorModel.is_model && this.custom_page.ready && this.extended_properties !== undefined
        ? this.extended_properties != null
        : true;
    },
    // isCustomPageReady() {
    //   return this.custom_page?.ready;
    // },
    isMQTT() {
      return isMQTT(this.iEquipment);
    },
    isSingleDevice() {
      return isSingleDevice(this.iEquipment);
    },
    protocol() {
      let references = this.$root?.config?.references || null;
      if (
        references &&
        this.iEquipment &&
        this.iEquipment?.connector_type?.id
      ) {
        let connector_type = (references?.connector_types || []).find(
          ({ id }) => id == this.iEquipment?.connector_type?.id
        );
        if (connector_type && connector_type?.allowed_protocols?.length) {
          return (
            (connector_type?.allowed_protocols || []).find(
              ({ id }) => id == this.iEquipment?.protocol?.id
            ) || null
          );
        }
      }
      return null;
    },
    mqtt_disconnect_modes() {
      return this.$root?.config?.references?.mqtt_disconnect_modes || [];
    },
    mqtt_disconnect_times() {
      return Array.from({ length: 12 }).map((i, ix) => (ix + 1) * 5);
    },
    formTabs() {
      let formTabs = [];
      formTabs.push({
        id: "tab_connector_home",
        icon: "fa fa-plug",
        title: this.$tc("tab_label.general", 1),
        error: ""
      });
      if (!this?.connectorModel?.is_model) {
        formTabs.push({
          id: "tab_connector_address",
          icon: "glyphicon glyphicon-pushpin",
          title: "address",
          error: ""
        });
      }
      formTabs.push({
        id: "tab_connector_notification",
        icon: "fa fa-send",
        title: this.$tc("tab_label.notification", 2),
        error: ""
      });
      if (this.iEquipment.id && !this?.connectorModel?.is_model) {
        formTabs.push({
          id: "tab_connector_keys",
          icon: "fa fa-key",
          title: this.$tc("tab_label.access", 2),
          error: ""
        });
      }
      if (this.iEquipment.id) {
        formTabs.push({
          id: "tab_data_list",
          icon: "glyphicon glyphicon-stats",
          title: this.$tc("tab_label.data_exibition", 2),
          error: ""
        });
      }
      if (this.isDataMappingEnabled) {
        formTabs.push({
          id: "tab_connector_data_mirroring",
          icon: "fa fa-share-alt",
          title: "data_mapping",
          error: ""
        });
      }
      if (this.custom_page.ready) {
        formTabs.push({
          id: "tab_custom_fields",
          icon: "fa fa-gears",
          title: this.$tc("tab_label.custom_fields", 2),
          error: this.isExtendedPropertiesValid ? "" : "data_required"
        });
      }
      return formTabs;
    },
    isDataMappingEnabled() {
      if (
        !this?.iEquipment?.id ||
        this.isMQTT ||
        this?.connectorModel?.is_model
      )
        return false;
      return true;
    },
    modelExtendedProperties: {
      set(value) {
        this.modelConfiguration.extendedProperties = value;
      },
      get() {
        return this.modelConfiguration.extendedProperties;
      }
    }
  },
  methods: {
    isSerialNumberValid(value) {
      return (
        this.isMQTT ||
        (value &&
          /[a-zA-Z0-9]{0,4}\.\d{1,5}/g.test(value) &&
          this.iEquipment.serial_number !=
          this.iEquipment.secondary_serial_number)
      );
    },
    resetData() {
      let data = defaultData();
      Object.keys(data).forEach((k) => {
        if (Object.prototype.hasOwnProperty.call(this.$data, k)) {
          this.$data[k] = data[k];
        }
      });
    },
    loading(state) {
      this.$emit("loading", state);
    },
    requireDeviceUpdate() {
      // this function can not be cached!!
      if (!this.equipment_id || this.cloneEnabled) return true;
      if (
        this.iEquipment.original_process_area_id &&
        this.payload.process_area_id &&
        this.payload.process_area_id != this.iEquipment.original_process_area_id
      ) {
        return true;
      }
      return (
        this?.iEquipment?.base_model_id &&
        !isEqual(
          (this.iEquipment?.portal_data?.model_device_ids || [])
            .map((id) => parseInt(id))
            .sort(),
          (this.payload?.portal_data?.model_device_ids || [])
            .map((id) => parseInt(id))
            .sort()
        )
      );
    },
    removeDevices(equipmentId) {
      let lst = this.$store.getters["dashboard/deviceList"]
        .filter(
          ({ connector_id }) => parseInt(connector_id) == parseInt(equipmentId)
        )
        .map(({ id }) => ({ device_id: id }));
      if (lst.length) {
        this.$store.commit("dashboard/REMOVE_RESOURCES", lst);
      }
    },
    validateCriticalChanges() {
      return new Promise((resolve) => {
        let lst = [];
        if (
          this.equipment_id &&
          this.iEquipment.original_base_model_id &&
          this.payload.base_model_id &&
          parseInt(this.payload.base_model_id) !=
          parseInt(this.iEquipment.original_base_model_id)
        ) {
          lst.push(["connector_model", true]);
        }
        // validates instance device list changes
        if (this.equipment_id && this.connectorModel.model) {
          let r1 = (this.$parent.$data?.deviceList || [])
            .map(({ reference_id }) => reference_id)
            .sort()
            .join("");
          let r2 = (this.$parent.$data.selectedDeviceIds || [])
            .map(
              (i) =>
                this.$store.getters["dashboard/deviceList"].find(
                  ({ id }) => parseInt(i) == parseInt(id)
                )?.reference_id || ""
            )
            .sort()
            .join("");
          if (r1 != r2) {
            lst.push(["device_list", true]);
          }
        }
        // validates incompatible protocol data memory type
        if (this.iEquipment.ignore_protocol_change) {
          lst.push(["incompatible_data_protocol_message", false]);
        }
        // add any other validation here... e.g. lst.push("data");
        if (lst.length) {
          let show_revert_message = lst.some((i) => i[1] == true);

          warnUnsavedChanged(
            this,
            {
              msg: this.$t("critical_changes_detected", {
                item: `<span class="text-danger"><i class="fa fa-exclamation-triangle"></i> ${lst
                  .map((i) => this.$tc(i[0], 1).toUpperCase())
                  .join(", ")}</span>`
              }),
              show_revert_message: show_revert_message
            },
            (r) => resolve(r)
          );
        } else {
          resolve(true);
        }
      });
    },
    save(stay, addAnother) {
      let self = this;
      let isNew = !self.equipment_id;
      let isModel = self.connectorModel.is_model;
      let allowModel = self.allowModel;
      let requireDeviceUpdate = this.requireDeviceUpdate();
      this.validateResourceQuota(
        "connector_plural",
        this.contract.maximum_connectors,
        this.contract.registered_connectors -
        (!isNew || (allowModel && isModel) ? 1 : 0)
      ).then((resp) => {
        if (resp == "proceed") {
          this.validateCriticalChanges().then((confirm) => {
            if (!confirm) return;
            let payload = self.payload;
            self.busy = true;
            self.service.save(payload).then((ret) => {
              if (ret.id && requireDeviceUpdate) {
                if (!isNew) {
                  this.removeDevices(ret.id);
                }
                this.fetchDeviceList(ret.id).then(() => {
                  if (addAnother || stay) {
                    this.$nextTick(() => {
                      setTimeout(
                        () => {
                          self.busy = false;
                        },
                        200,
                        this
                      );
                    });
                  }
                });
              } else {
                self.busy = false;
              }
              if (self.validateSaveResponse(ret)) {
                if (this.selectedParentNode) {
                  this.$store.dispatch("dashboard/setParent", { dbKey: 'tree_connector', id: ret.id, parentId: this.selectedParentNode });
                }
                if (typeof this.$parent.resetHash == "function") {
                  this.$parent.resetHash();
                }
                self.$store.dispatch("user/configureUserContract");
                self.iEquipment.id = ret.id;
                self.iEquipment.etag = ret.etag;
                self.iEquipment.mqtt_topic_prefix = ret.mqtt_topic_prefix;
                self.iEquipment.ignore_protocol_change = false;

                self.$store.dispatch("dashboard/setConnectorValue", [ret]);
                if (
                  Object.keys(ret?.user_data?.extended_properties || {}).length
                ) {
                  self.$store.dispatch("dashboard/setConnectorPropertyValue", {
                    connector_id: ret.id,
                    properties: ret.user_data.extended_properties
                  });
                }
                self.exitSuccess = true;
                self.showAlert(() => {
                  if (addAnother) {
                    if (self.$route.path != "/dashboard/edit/connector/0")
                      self.$router.push(`/dashboard/edit/connector/0`);
                    self.$emit("refreshUI");
                  } else if (stay) {
                    if (self.$route.path.endsWith("/0")) {
                      self.$router.push({
                        name: "route-connector-form",
                        params: {
                          connector_id: self.iEquipment.id
                        }
                      });
                    }
                    self.nav(self.iEquipment.name);
                    self.$emit("refreshUI", self.iEquipment.id);
                    if (payload.ignore_protocol_change) {
                      self.$nextTick(() => {
                        self.$parent.activateTab("#data_list");
                      });
                    }
                  } else {
                    self.close("save");
                    return;
                  }
                }, true);
              } else {
                self.showAlert();
              }
            });
          });
        } else if (resp == "upgrade") {
          this.$router.push("/dashboard/plan");
        }
      });
    },
    clone: function() {
      let self = this;
      let payload = self.payload;
      delete payload.id;
      delete payload.contract_id;
      delete payload.connector_type_id;
      delete payload.protocol_id;
      delete payload.updates_datetime;
      delete payload.optimizes_frames;
      self.busy = true;
      self.service.duplicate(self.equipment_id, payload).then((ret) => {
        self.busy = false;
        if (self.validateSaveResponse(ret)) {
          self.exitSuccess = true;
          if (this.selectedParentNode) {
            this.$store.dispatch("dashboard/setParent", { dbKey: 'tree_connector', id: ret.id, parentId: this.selectedParentNode });
          }
          if (typeof this.$parent.resetHash == "function") {
            this.$parent.resetHash();
          }
          self.$store.dispatch("fetchEquipmentList");
          self.$store.dispatch("user/configureUserContract");
          self.showAlert(() => {
            self.close("clone");
          });
        } else {
          self.showAlert();
        }
      });
    },
    remove: function() {
      this.doRemove(this.payload, this.service);
    },
    setupCustomPage(entry) {
      let self = this;
      let url = "";
      let equipment_extended_page =
        (self.$root.config && self.$root.config.equipment_extended_page) || "";
      if (equipment_extended_page) {
        // // listen to the custom page changes if applciable
        let access_token = this.$cookies.get("access_token");
        let id = encodeURIComponent(self.equipment_id || "");
        let api = encodeURIComponent(self.$http.options.root);
        let app_static_dir = encodeURIComponent(self.$http.options.dashboard);
        let contract_id = encodeURIComponent(this.contract.id);
        url = `${equipment_extended_page}?id=${id}&api=${api}&access_token=${access_token}&app_static_dir=${app_static_dir}&contract_id=${contract_id}`;
        if (this?.connectorModel?.model?.id) {
          url += `&base_model_id=${this.connectorModel.model.id}`;
        }
      }
      if (url == this.custom_page.url) return;
      if (
        url &&
        entry &&
        "user_data" in entry &&
        entry.user_data &&
        "extended_properties" in entry.user_data
      ) {
        this.$set(
          this,
          "extended_properties",
          entry.user_data.extended_properties
        );
      }
      if (this.modelConfiguration.enabled) {
        this.custom_page.ready = true;
      } else if (this?.$refs?.custom_page) {
        this.$set(this, "custom_page", { ready: false, url: url });
        this.$refs.custom_page.contentDocument.location.replace(url);
      }
    },
    onWindowMessage(e) {
      if (
        e.data &&
        typeof e.data == "object" &&
        "extended_properties" in e.data
      ) {
        this.$set(this, "extended_properties", e.data.extended_properties);
      }
    },
    onCustomPageReady(e) {
      if (e?.target?.contentDocument?.location?.href != "about:blank") {
        this.custom_page.ready = true;
        window.removeEventListener("message", this.onWindowMessage);
        window.addEventListener("message", this.onWindowMessage);
      }
    },
    onModelChanged(enabled) {
      if (enabled !== undefined) {
        this.modelConfiguration.enabled = enabled;
        if (enabled) {
          let local_properties =
            this.modelConfiguration.extendedProperties || [];
          let connector_properties =
            this?.iEquipment?.portal_data?.extended_properties || [];
          let contract_properties =
            this?.contract?.portal_data?.equipment_extended_properties || [];
          this.$set(
            this.modelConfiguration,
            "extendedProperties",
            local_properties.length
              ? local_properties
              : connector_properties.length
                ? connector_properties
                : contract_properties
          );
        }
      }
    },
    onRestoreExtendedProperties() {
      this.$set(this.modelConfiguration, "extendedProperties", []);
      this.$nextTick(() => {
        this.onModelChanged(true);
      });
    },
    initData(entry) {
      let self = this;
      self.initialized = false;
      if (entry) {
        let dftInterval =
          (self.poolingInterval &&
            self.poolingInterval &&
            self.poolingInterval.options &&
            self.poolingInterval.options[0].value) ||
          0;
        entry.pooling_interval =
          (entry.pooling_interval || 0) * 1000 || dftInterval;
        self.$set(self, "iEquipment", JSON.parse(JSON.stringify(entry)));
        //self.process_area.id = self.iEquipment.process_area.id;
        self.input_equipment_type.type_id =
          self.iEquipment.connector_type.id || "";
        self.input_equipment_type.protocol_id =
          self.iEquipment.protocol.id || "";
        self.input_equipment_type.optmized =
          self.iEquipment.optimizes_frames || false;
        self.input_equipment_type.datetime_update =
          self.iEquipment.updates_datetime || false;

        // actual attributes must be confirmed:
        let connectorModel = self.connectorModel;
        connectorModel.is_model =
          ("base_model" in self.iEquipment && self.iEquipment.base_model) ||
          false;
        if (self.iEquipment.base_model_id) {
          connectorModel.model = {
            id: self.iEquipment.base_model_id
          };
          self.iEquipment.original_base_model_id =
            self.iEquipment.base_model_id;
        } else {
          connectorModel.model = null;
        }
        self.iEquipment.original_process_area_id =
          self.iEquipment.process_area.id;

        self.$set(self, "connectorModel", connectorModel);
      } else {
        // self.setupCustomPage(entry);
        if (self.isOutOfQuota) {
          self.connectorModel.is_model = true;
        }
        self.iEquipment.pooling_interval = self.poolingInterval.value;
      }
      let default_pa =
        (self.$store.getters["processArea/processAreas"] || []).find(
          (i) => i.is_default
        ) || null;
      self.process_area.id =
        self.iEquipment.process_area.id || (default_pa && default_pa.id) || 0;
      //
      if (entry && "notification_enabled" in entry) {
        this.$set(
          this.iEquipment,
          "notification_enabled",
          entry.notification_enabled
        );
      }
      if (!this.iEquipment.screen_id || !(this.screenList || []).some(({ id }) => parseInt(id) == parseInt(this.iEquipment.screen_id))) {
        this.iEquipment.screen_id = '';
        this.initScreenSelection();
      }
      self.initGroups(entry);
      self.$emit("connectorTypeChanged", this.iEquipment);
      this.$nextTick(() => {
        self.initialized = true;
        this.$nextTick(() => {
          self.setupCustomPage(this.iEquipment);
        });
      });
    },
    initScreenSelection(connector) {
      connector = connector || this.iEquipment;
      // is it a new connector or does not have yet a screen assigned:
      if (!connector.screen_id && this.screenList && this.screenList.length) {
        let screen = null;
        // get it from the connector model
        if (this?.connectorModel?.model?.screen_id) {
          screen = this.screenList.find(
            (i) => i.id == this.connectorModel.model.screen_id
          );
        }
        // or get it from default screen configuration
        if (!screen) {
          screen = this.screenList.find((i) => "default" in i && i.default);
        }
        // or just get the first one
        if (!screen) {
          screen = this.screenList[0];
        }
        connector.screen_id = screen.id;
      }
    },
    setup() {
      let self = this;
      if (self.equipment_id) {
        self.create_standard_device = false; // only allowed when creating new equipment
      } else {
        self.create_standard_device = true;
      }
      if (self.equipment_id) {
        self.busy = true;
        self.service
          .get(this.equipment_id, this.contract && this.contract.id)
          .then((ret) => {
            self.busy = false;
            if (ret) {
              self.backup = JSON.parse(JSON.stringify(ret));
              self.initData(ret);
              if (self.$utils.gup("a") == "c") {
                this.cloneEnabled = true;
                self.nav(`${self.iEquipment.name} (${self.$t("copying")})`);
                this.iEquipment.serial_number = "";
                this.iEquipment.secondary_serial_number = "";
                this.iEquipment.name = "";
              } else if (this.$utils.gup("a") == "d") {
                self.nav(`${self.iEquipment.name} (${self.$t("deleting")})`);
                self.$nextTick(function() {
                  self.onButtonRemoveClick();
                });
              } else {
                self.nav(self.iEquipment.name);
              }
              self.ready = true;
            } else {
              self.noData();
            }
          });
        return;
      }
      self.busy = false;
      self.initData();
      self.nav(self.$tc("new"));
      self.loading(false);
      self.ready = true;
    },
    nav(title) {
      this.$emit("titleChanged", title);
      this.$emit("navChanged", {
        previous: "/dashboard/edit/connector",
        items: [
          { name: "connector_plural", url: "/dashboard/edit/connector" },
          { name: this.iEquipment.name, url: "/dashboard/edit/connector" }
        ],
        isMQTT: this.isMQTT
      });
    },
    filterHistoryEnabledData(data) {
      return (data && data.history_enabled) || false;
    },
    fetchScreens() {
      let query = {
        contract_id: this.$store.getters["user/contract"].id
      };
      this.$store.dispatch("dashboard/fetchScreens", query);
    },
    fetchDeviceList(connectorId) {
      var query = {
        resource: "device",
        connectorId: connectorId,
        forceUpdate: true,
        once: true
      };
      return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
    },
    onEditScreen(opt) {
      if (opt == 'new') {
        let params = this.equipment_id ? `?connector_id=${this.equipment_id}` : "";
        this.$router.push(`/dashboard/screen/edit/new${params}`);
      }
      else {
        if (this.canEditScreen) {
          this.$router.push(
            `/dashboard/screen/edit/${this.iEquipment.screen_id}`
          );
        }
      }
    },
    onProcessAreaRemoved(processAreaId) {
      let pa = (this.processAreas || []).find(({ is_default }) => is_default);
      let lst;
      lst = (this.$store.getters["dashboard/connectorList"] || [])
        .filter(
          ({ process_area }) =>
            parseInt(process_area.id) == parseInt(processAreaId)
        )
        .map((item) => ({ id: item.id, process_area: { ...pa } }));
      if (lst.length) {
        this.$store.dispatch("dashboard/setConnectorValue", lst);
      }
      lst = (this.$store.getters["dashboard/deviceList"] || [])
        .filter(
          ({ process_area }) =>
            parseInt(process_area.id) == parseInt(processAreaId)
        )
        .map((item) => ({ id: item.id, process_area: { ...pa } }));
      if (lst.length) {
        this.$store.dispatch("dashboard/setDeviceValue", lst);
      }
      if (this.equipment_id) {
        setTimeout(
          () => {
            this.service.get(this.equipment_id).then((resp) => {
              if (resp && resp.etag) {
                console.log(`${this.iEquipment.etag} ${resp.etag}`);
                this.iEquipment.etag = resp.etag;
              }
            });
          },
          1000,
          this
        );
      }
      console.log(processAreaId);
    },
    hasIncompatibleMemoryTypes() {
      if (!this.ready || !this.equipment_id) return false;
      let compatible_memory_types = (this.$root?.config?.references?.data_memory_types || []).filter(
        (i) => i.allowed_protocols.some(({ id }) => parseInt(id) == parseInt(this.iEquipment?.protocol?.id))
      );
      let device_list = this.$store.getters["dashboard/deviceList"].filter(
        ({ connector_id }) => parseInt(connector_id) == parseInt(this.iEquipment?.id)
      );
      let data_list = this.$store.getters["dashboard/dataList"].filter(
        (i) => {
          if (i.is_local) return false;
          if (!device_list.filter(({ id }) => parseInt(id) == i.device_id)) return false;
          return !compatible_memory_types.find(({ id }) => parseInt(id) == i.memory_type.id);
        }
      );
      return data_list.length > 0;
    }
  },
  beforeCreate() {
    this.service = new ConnectorService();
  },
  created() {
    this.rule = "EquipamentoEscrita";
    this.fetchScreens();
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onWindowMessage);
  },
  mounted() {
    this.selectedParentNode = this.$utils.gup("parent");
    this.$emit(
      "titleChanged",
      this.equipment_id ? this.equipment_id : this.$tc("new")
    );
    this.setup();
  },
  i18n: {
    messages
  }
};
</script>

<style scoped>
.content-title {
  padding: 10px 0 10px 10px;
  border-top: 1px solid lightgray;
}
.scrollable-iframe {
  border: 0;
  min-height: 500px;
  overflow: auto;
  width: 100%;
}
.item-selection {
  border: 0;
  padding: 5px 10px;
  margin: 0 5px;
  text-align: center;
  display: inline-block;
}

.btn .badge {
  margin-left: 10px;
}

.overlay-local {
  position: absolute;
  top: 30%;
  left: 50%;
  font-size: 25pt;
  opacity: 0.5;
  z-index: 1;
}

.btn-gap {
  margin-right: 10px;
}

.btn-clone {
  margin: 0 50px;
}

.protected {
  position: relative;
}

.protected-disabled-area {
  position: absolute;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  opacity: 0.4;
}

.protected-disabled-area:hover {
  cursor: not-allowed;
}

.basic-device-box {
  margin: 20px 0 10px 0;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

div.table-title {
  padding: 0 0 5px 0;
  margin-bottom: 5px;
  border-bottom: 1px solid #cdcdcd;
}

div.table-title .label {
  font-size: 14pt;
  color: #777;
  text-align: left;
  padding-left: 0.8em;
}

div.tab-content {
  min-height: 450px;
}

div.nav-tabs-custom > ul > li > a {
  padding: 10px 8px;
}

div.nav-tabs-custom > ul > li.active > a,
div.nav-tabs-custom > ul > li.active > span,
div.nav-tabs-custom > ul > li.active > i {
  /* color: #337ab7; */
  font-weight: 600;
}
div.nav-tabs-custom > ul > li.active:hover > a,
div.nav-tabs-custom > ul > li.active:hover > span,
div.nav-tabs-custom > ul > li.active:hover > i {
  cursor: pointer;
  /* color: #337ab7; */
}

div.nav-tabs-custom > ul > li > span.badge-tab,
div.nav-tabs-custom > ul > li.active > span.badge-tab {
  position: absolute;
  right: -2px;
  top: 7px;
  z-index: 1;
  font-size: 70%;
  color: #a94442;
  background-color: transparent;
}
.form-inline-model {
  margin: -5px 0 -20px 0;
  background-color: transparent;
  /* border-bottom: 1px solid lightgray; */
}

.process-area {
  margin-bottom: 20px;
}

.addon-link {
  /*color: #3c8dbc;*/
}

.addon-link:hover {
  cursor: pointer;
  /* color: #72afd2; */
}

section::v-deep > div > div > .form-group > .input-group > .form-control,
div::v-deep > div > .form-group > .input-group > .form-control,
.input-group::v-deep > .form-control {
  z-index: inherit;
}
</style>
