<template>
  <section class="me">
    <div class="row">
      <div class="col-md-12">
        <div class="create-box">
          <div v-if="id" class="edit-toolbar">
            <span>
              <i class="fa fa-pencil"></i>
              {{ $t("edit_token") }}
            </span>
            <span class="pull-right btn btn-xs" @click.stop.prevent="reset">
              <i class="fa fa-close"></i>
            </span>
          </div>
          <div v-else @click.stop.prevent="expanded = !expanded">
            <span class="clicable no-select"
              >{{ $t("create_new_token") }}
              <i
                :class="expanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row form-box" v-if="expanded">
      <div class="col-md-3 col-sm-5 form-group">
        <label for="">{{ $tc("screen", 1) }}</label>
        <select
          v-model="formData.screen_id"
          class="form-control input-sm"
          style="font-family: FontAwesome"
        >
          <option v-if="!connector.screen_id" value="">
            &#xf252;&nbsp;&nbsp;{{ $t("loading") }}
          </option>
          <option
            v-for="(item, ix) in screenList"
            v-bind:key="ix * 97"
            v-bind:value="item.id"
            v-bind:title="
              '#' +
              item.id +
              ' - ' +
              (item.public ? 'public - ' : 'private - ') +
              item.description
            "
          >
            <template v-if="item.public">&#xf0ac;</template>
            <template v-else>&#xf0b1;</template>
            &nbsp;&nbsp;{{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-md-4 col-sm-7 form-group">
        <label for="">{{ $t("redirect_url") }}</label>
        <input
          type="text"
          class="form-control input-sm"
          :placeholder="$t('redirect_url')"
          v-model="formData.redirect_url"
        />
      </div>

      <div class="col-md-2 col-sm-6 col-xs-4 form-group">
        <label for="formData_access_timeout"
          >{{ $t("access_timeout") }}
          <div class="top-sulfix-text" v-if="expiresAt">
            {{ $t("up_to") }} {{ expiresAt }}
            <i
              class="fa fa-close clicable"
              :title="$t('remove')"
              @click.stop.prevent="expiresAt = ''"
            ></i>
          </div>
        </label>
        <div class="input-group">
          <input
            id="formData_access_timeout"
            type="number"
            class="form-control input-sm text-center input-with-sulfix"
            :placeholder="$t('access_timeout')"
            v-model="formData.access_timeout"
            min="references.min_timeout"
            max="references.max_timeout"
            inc="1"
          />
          <div class="input-sulfix">min</div>
          <div
            class="input-group-addon btn"
            ref="btndatepicker"
            :title="$t('due_date')"
          >
            <i class="fa fa-calendar"></i>
            <input type="hidden" ref="datepicker" />
          </div>
        </div>
      </div>

      <div class="col-md-1 col-sm-3 col-xs-4 form-group text-center">
        <label for="">{{ $tc("enabled", 1) }}</label>
        <div class="input-control clicable">
          <span
            style="font-size: 150%"
            @click.stop.prevent="formData.enabled = !formData.enabled"
            :title="$t(formData.enabled ? 'on' : 'off')"
          >
            <i class="fa fa-toggle-on text-green" v-if="formData.enabled"></i>
            <i class="fa fa-toggle-off" v-else></i>
          </span>
        </div>
      </div>

      <div
        class="col-md-2 col-sm-3 col-xs-4 form-group text-center"
        v-if="!busy"
      >
        <label for="">{{ $tc("options", 1) }}</label>
        <div style="margin-top: 3px">
          <span
            class="btn btn-xs btn-primary"
            :class="{ disabled: !isValid }"
            style="margin-right: 5px"
            @click.stop.prevent="save"
            :title="$tc(id ? 'save' : 'new', 1)"
          >
            <i :class="id ? 'fa fa-check' : 'fa fa-plus'"></i>
          </span>
          <span
            class="btn btn-xs btn-default"
            @click.stop.prevent="id ? remove() : reset()"
            :title="$t(id ? 'remove' : 'reset')"
          >
            <i :class="id ? 'fa fa-trash' : 'fa fa-undo'"></i>
          </span>
        </div>
      </div>

      <div class="col-xs-12 col-sm-10 form-group form-group-sm">
        <label for="">{{ $tc("description", 1) }}</label>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('text')"
          v-model="formData.description"
        />
      </div>
    </div>
    <SearchableTable
      class="searchable-table"
      v-if="items"
      :searchEnabled="false"
      :items="items"
      :fields="fields"
      :commands="commands"
      @command="onCommand"
    >
      <template v-slot:toggle="entry">
        <span @click.stop.prevent="toggleItem(entry.item)">
          {{ $t(entry.item.enabled ? "yes" : "no") }}
          <i
            :class="
              busy
                ? 'fa fa-refresh fa-spin'
                : entry.item.enabled
                ? 'fa fa-toggle-on text-green'
                : 'fa fa-toggle-off'
            "
          ></i>
        </span>
      </template>
    </SearchableTable>
    <QRCodeViewer
      v-if="qrcodeItem && qr_content"
      :content="qr_content"
      @close="qrcodeItem = null"
      :filename="`qr_${connector.name}.jpg`"
    >
      <template v-slot:contentAction="entry">
        <a :href="entry.content" @click.stop.prevent="copy()">
          <i class="fa fa-copy"></i>
          <span style="margin-left: 5px">{{ entry.content }}</span>
        </a>
      </template>
    </QRCodeViewer>
  </section>
</template>

<script>
import TokenService from "@/services/token.js";
import SearchableTable from "@/components/searchable-table.vue";
import QRCodeViewer from "@/components/widgets/qrcode-viewer.vue";
const initialState = () => {
  return {
    formData: {
      enabled: true,
      access_timeout: 5,
      expires_at: "",
      redirect_url: "",
      screen_id: "",
      description: ""
    },
    id: "",
    etag: "",
    busy: false,
    items: null,
    qrcodeItem: null,
    expanded: true
  };
};

export default {
  name: "FormConnectorPublicTokens",
  components: {
    SearchableTable,
    QRCodeViewer
  },
  props: {
    connector: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return initialState();
  },
  computed: {
    fields() {
      let self = this;
      return [
        {
          name: "screen_id",
          title: "screen",
          parser(item) {
            return (
              (self.screenList || []).find(
                (screen) => screen.id == item.screen_id
              ) || {
                name: self.$t("item_not_found")
              }
            ).name;
          }
        },
        {
          name: "description",
          title: "description"
        },
        {
          name: "redirect_url",
          title: "redirect_url",
          parser(item) {
            return item.redirect_url || self.$t("page.sign_in_page");
          }
        },
        {
          name: "access_timeout",
          title: "access_timeout",
          style: {
            "text-align": "center"
          },
          parser(item) {
            let dt = self.fmtExpiresAt(item.expires_at);
            dt = dt ? `(${self.$t("up_to")} ${dt})` : "";
            return `${self.$tc("for", 2)} ${item.access_timeout
              } min ${dt}`.toLowerCase();
          }
        }
        // {
        //   name: "toggle",
        //   title: "enabled",
        //   style: {
        //     "text-align": "center"
        //   }
        // }
      ];
    },
    commands() {
      if (this.$can("manage", "TokenAcessoRemotoEscrita")) {
        return [
          { name: "edit", title: "edit", icon: "fa fa-pencil" },
          {
            name: "toggleItem",
            title: (item) => {
              return this.$tc(item.enabled ? "enabled" : "disabled", 1);
            },
            icon: (item) => {
              return item.enabled
                ? "fa fa-toggle-on text-green"
                : "fa fa-toggle-off";
            }
          },
          {
            name: "copy",
            title: this.$t("copy", { item: "url" }),
            icon: "fa fa-copy"
          },
          { name: "qrcode", title: "qrcode", icon: "fa fa-qrcode" },
          { name: "remove", title: "remove", icon: "fa fa-trash" }
        ];
      }
      return [];
    },
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        (i) => i.id > -1
      );
    },
    isValid() {
      let timeout = parseInt(this.formData.access_timeout);
      return (
        timeout >= this.references.min_timeout &&
        timeout <= this.references.max_timeout &&
        (!this.formData.redirect_url ||
          this.$utils.isValidURL(this.formData.redirect_url))
      );
    },
    references() {
      let ret = this?.$root?.config?.references?.public_access || {
        default_timeout: 5,
        min_timeout: 1,
        max_timeout: 15
      };
      return ret;
    },
    payload() {
      let payload = JSON.parse(JSON.stringify(this.formData));
      payload.connector_id = this.connector.id;
      payload.access_timeout = parseInt(this.formData.access_timeout);
      payload.screen_id = parseInt(payload.screen_id);
      if (this.id) {
        payload.id = this.id;
      }
      if (this.etag) {
        payload.etag = this.etag;
      }
      if (payload.expires_at) {
        payload.expires_at = moment(payload.expires_at)
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        payload.expires_at = null;
      }
      return payload;
    },
    expiresAt: {
      set(value) {
        this.formData.expires_at = value;
      },
      get() {
        return this.fmtExpiresAt(this.formData.expires_at);
      }
    },
    qr_content() {
      return this.qrcodeItem && this.srv.toPublicUrl(this.qrcodeItem) || "";
    }
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  methods: {
    reset() {
      let items = JSON.parse(JSON.stringify(this.items));
      Object.assign(this.$data, initialState());
      this.formData.screen_id = this?.connector?.screen_id || "";
      this.formData.access_timeout = this.references.default_timeout;
      this.items = items;
    },
    save() {
      if (!this.isValid) return;
      if (this.busy) return;
      this.busy = true;
      this.srv.save(this.payload).then((response) => {
        this.busy = false;
        if (response && response.id) {
          this.reset();
          this.fetchTokens();
        } else {
          if (response) {
            this.$swal({
              title: this.$t("an_error_has_occurred"),
              text: response,
              icon: "error"
            });
          }
        }
      });
    },
    fetchTokens() {
      let query = { connector_id: this.connector.id };
      this.srv.fetch(query).then((response) => {
        if (response && response.length) {
          this.$set(this, "items", response);
        } else {
          this.$set(this, "items", null);
          this.expanded = true;
        }
        this.$nextTick(() => {
          this.busy = false;
        });
      });
    },
    onCommand(command) {
      if (command.name in this) {
        this[command.name](command.target);
      }
    },
    edit(item) {
      for (var p in this.formData) {
        this.formData[p] = item[p];
      }
      this.expanded = true;
      this.etag = item.etag;
      this.id = item.id;
      if (this.formData.expires_at) {
        let $picker = $(this.$refs.btndatepicker).data("daterangepicker");
        if ($picker) {
          $picker.setEndDate("");
          $picker.setStartDate(moment(this.formData.expires_at));
        }
      }
    },
    remove(item) {
      if (this.busy) return;
      let entry = item || null;
      if (!entry && this.id && this.etag) {
        entry = { id: this.id, etag: this.etag };
      }
      this.busy = true;
      this.srv.remove(entry).then(() => {
        let expanded = this.expanded;
        this.reset();
        this.expanded = expanded;
        this.fetchTokens();
      });
    },
    copy(item) {
      console.log(item)
      let url = this.srv.toPublicUrl(item || this.qrcodeItem) || "";
      this.$utils.toClipboard(url);
      let key =
        navigator.userAgent.toUpperCase().indexOf("FIREFOX") >= 0
          ? "Ctrl+Shift+P"
          : "Ctrl+Shift+N";
      let infoHtml = this.$t("hints.copy_and_open_anonymous", { key: key });
      let options = {
        type: "info",
        icon: "copy",
        iconPack: "fontawesome",
        singleton: true,
        duration: 10000, // 10s
        position: "bottom-right",
        theme: "outline",
        action: {
          icon: "fa-close",
          onClick: (e, me) => {
            me.goAway(0);
          }
        }
      };
      this.$toasted.clear();
      this.$toasted.show(infoHtml, options);
    },
    toggleItem(item) {
      // for (var p in this.formData) {
      //   this.formData[p] = item[p];
      // }
      // this.etag = item.etag;
      // this.id = item.id;
      this.edit(item);
      this.formData.enabled = !this.formData.enabled;
      this.save();
    },
    navigate(item) {
      if (!item.enabled) return;
      this.$utils.navigate(this.srv.toPublicUrl(item), { new_tab: true });
    },
    qrcode(item) {
      this.qrcodeItem = item;
    },
    fmtExpiresAt(isoDT) {
      return isoDT ? moment(isoDT).format("DD/MM/YY H:mm") : "";
    }
  },
  created() {
    this.srv = new TokenService("public");
    this.reset();
    this.fetchTokens();
  },
  beforeDestroy() {
    this.$toasted.clear();
    $(this.$refs.btndatepicker)
      .daterangepicker()
      .remove();
  },
  mounted() {
    let self = this;
    $(this.$refs.btndatepicker).daterangepicker(
      {
        showOn: "button",
        singleDatePicker: true,
        timePicker: true,
        timePicker24Hour: true,
        opens: "center",
        drops: "down",
        autoUpdateInput: true,
        minDate: moment().add(1, "minute"),
        showTimezone: true,
        locale: {
          format: "DD/MM/YY H:mm",
          applyLabel: self.$t("apply"),
          cancelLabel: self.$t("Cancel"),
          fromLabel: self.$t("From"),
          toLabel: self.$t("To"),
          customRangeLabel: self.$t("Custom"),
          daysOfWeek: [
            self.$t("calendar.Su"),
            self.$t("calendar.Mo"),
            self.$t("calendar.Tu"),
            self.$t("calendar.We"),
            self.$t("calendar.Th"),
            self.$t("calendar.Fr"),
            self.$t("calendar.Sa")
          ],
          monthNames: [
            self.$t("calendar.January"),
            self.$t("calendar.February"),
            self.$t("calendar.March"),
            self.$t("calendar.April"),
            self.$t("calendar.May"),
            self.$t("calendar.June"),
            self.$t("calendar.July"),
            self.$t("calendar.August"),
            self.$t("calendar.September"),
            self.$t("calendar.October"),
            self.$t("calendar.November"),
            self.$t("calendar.December")
          ],
          firstDay: 1
        }
      },
      (start) => {
        self.expiresAt = start.toISOString();
      }
    );
  }
};
</script>

<style scoped>
.me {
  padding-bottom: 10px;
}
.form-box {
  border-radius: 5px;
  background-color: whitesmoke;
  padding: 10px 5px 5px 5px;
}

label {
  position: relative;
  width: 100%;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.create-box {
  position: relative;
  margin: 10px 0;
}

.edit-toolbar {
  border-top: 1px solid lightgray;
  margin: -3px -15px 0px -15px;
  padding: 2px 5px 0 5px;
}

input.input-with-sulfix {
  padding: 0 34px 0 7px;
}

input.input-with-sulfix::-webkit-outer-spin-button,
input.input-with-sulfix::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-with-sulfix {
  -moz-appearance: textfield;
}

.input-sulfix {
  position: absolute;
  right: 50px;
  top: 4px;
  z-index: 3;
}

.top-sulfix-text {
  color: #9c0b00;
  font-weight: normal;
  font-size: 8pt;
  position: absolute;
  right: 0px;
  top: -8px;
}

.top-sulfix-text > i {
  color: #ab7470;
}
.searchable-table {
  overflow-x: auto;
}
</style>
