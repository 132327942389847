<script>
import PanelHeaderEquipmentList from "@/components/panel-header-equipment-list.vue";
import MixinAlert from "@/project/mixin-alert.js";
import { contractPlanConst } from "@/assets/constants.js";

function defaultData() {
  return {
    entity: "",
    busy: false,
    items: null,
    parent: null,
    maxResult: 0,
    deleted_id: "",
    next_page: null,
    curr_page: null,
    prev_page: null,
    sortOrder: null,
    title: "loading",
    nav: null,
    multiSelection: {
      key: "",
      values: []
    }
  };
}
export default {
  name: "DashboardEditPickerBase",
  components: { PanelHeaderEquipmentList },
  mixins: [MixinAlert],
  props: {
    header: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data: defaultData,
  computed: {
    pagination() {
      return "page_size" in this.$root.config.equipment_selection &&
        this.$root.config.equipment_selection.page_size
        ? true
        : false;
    },
    contract() {
      let user = this.$store.getters["user/loggedUser"];
      return (user && user.contract) || null;
    },
    isFreePlan() {
      return this?.contract?.contract_plan?.id == contractPlanConst.FREE;
    },
    connector() {
      return (
        (this.connectorId &&
          this.$store.getters["dashboard/connectorList"].find(
            ({ id }) => parseInt(id) == parseInt(this.connectorId)
          )) ||
        this?.parent?.device?.connector ||
        this?.parent?.connector ||
        null
      );
    },
    connectorId() {
      return parseInt(
        this.connector_id || this.$route.params.connector_id || 0
      );
    },
    deviceId() {
      return parseInt(this.device_id || this.$route.params.device_id || 0);
    },
    dataId() {
      return parseInt(this.data_id || this.$route.params.data_id || 0);
    },
    alarmId() {
      return parseInt(this.alarm_id || this.$route.params.alarm_id || 0);
    },
    isModel() {
      return (this.connector && this.connector.base_model) || false;
    },
    isModelBased() {
      return this?.connector?.base_model_id || false;
    },
    isEmpty() {
      // it does not return true by default. It will returns true after fetch items without succeed instead
      return this.items !== null && this?.items?.length == 0;
    },
    isMQTT() {
      return this?.connector?.protocol?.is_mqtt_protocol || false;
    },
    screenList() {
      return (this.$store.getters["dashboard/screens"] || []).filter(
        ({ id }) => id > -1
      );
    },
    nSelected() {
      return (this.multiSelection.values || []).length;
    },
    cloneEnabled() {
      return this.$utils.gup("a") == "c";
    }
  },
  methods: {
    removalMessage(item) {
      return ""; // can be implemented at child level
    },
    reset() {
      Object.assign(this.$data, defaultData());
    },
    confirmRemoval(option) {
      let self = this;
      let item = option?.item || option?.items; // the object entity
      let type = option.type; // the type of entity ("connector" | "device" | "data" | "alarm")
      let rule = option.rule; // the access control rule to be validated

      return new Promise((resolve) => {
        self.validatePrivileges("manage", rule).then((hasAccess) => {
          if (hasAccess) {
            self
              .$swal({
                title: self.$t("are_you_sure"),
                content:
                  self.removalMessage(item) ||
                  self.warningContent(
                    type,
                    item.name,
                    "you_wont_be_able_to_revert_this"
                  ),
                icon: "warning",
                buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
              })
              .then(function(confirmed) {
                resolve(confirmed);
              });
          } else {
            resolve(false);
          }
        });
      });
    },
    validateAndRemove(option, callback) {
      this.confirmRemoval(option).then((confirmed) => {
        if (confirmed) {
          let _cb = callback || function() { };
          let item = option.item; // the object entity
          let service = option.service; // then rest service (ConnectorService | DeviceService | DataService | AlarmService)
          this.busy = true;
          this.$emit("loading", true);
          service.remove(item).then((ret) => {
            this.$emit("loading", false);
            this.busy = false;
            if (this.validateDeleteResponse(ret)) {
              this.$store.dispatch("user/configureUserContract");
              if (option.resource_key) {
                this.$store.dispatch("dashboard/removeResources", [{
                  [option.resource_key]: item.id
                }]);
              }
              this.exitSuccess = true;
              let items = this.items.filter(function(i) {
                return i.id != item.id;
              });
              this.maxResult = this.maxResult > 0 ? this.maxResult - 1 : 0;
              this.$set(this, "items", items);
              // this.fetchItems(this.curr_page);
              this.showAlert();
              _cb(true);
            } else {
              this.showAlert();
              _cb(false);
            }
          });
        }
      });
    },
    validateAndMassRemove(option, callback) {
      this.confirmRemoval(option).then((confirmed) => {
        if (confirmed) {
          let _cb = callback || function() { };
          let payload = {
            remove_type: "partial",
            resource_ids: this?.multiSelection?.values,
            contract_id: this?.contract?.id,
            connector_id: this?.connectorId
          };
          let service = option.service; // then rest service (ConnectorService | DeviceService | DataService | AlarmService)
          this.busy = true;
          this.$emit("loading", true);
          service.removeMultiple(payload).then((ret) => {
            this.$emit("loading", false);
            this.busy = false;
            if (this.validateDeleteResponse(ret)) {
              this.$store.dispatch("user/configureUserContract");
              if (option.resource_key) {
                this.$store.dispatch(
                  "dashboard/removeResources",
                  payload.resource_ids.map((id) => ({
                    [option.resource_key]: id
                  }))
                );
              }
              this.exitSuccess = true;
              let items = this.items.filter(function(i) {
                return payload.resource_ids.indexOf(i.id) == -1;
              });
              this.maxResult = this.maxResult > 0 ? this.maxResult - 1 : 0;
              this.$set(this, "items", items);
              this.showAlert();
              _cb(true);
            } else {
              this.showAlert();
              _cb(false);
            }
          });
        }
      });
    },
    onCommand(ev) {
      if (this.cloneEnabled) return;
      let self = this;
      if (ev && ev.name) {
        if (ev.name in self && typeof self[ev.name] == "function") {
          self[ev.name](ev);
        } else {
          self
            .$swal({
              title: self.$t("not_allowed"),
              text: self.$t("feature_not_available"),
              icon: "error"
            })
            .then((result) => {
              self.alert = null;
            });
        }
      }
    },
    onSelect(entry) {
      if (this.cloneEnabled) return;
      if (entry.length !== undefined && this.multiSelection.key) {
        this.$set(this.multiSelection, "values", entry);
      } else {
        this.onCommand({
          name:
            this?.select && typeof this.select == "function"
              ? "select"
              : "edit",
          target: entry
        });
      }
    },
    clearSelection() {
      this.multiSelection.values = []
    },
    newConnector(command) {
      this.$router.push("/dashboard/edit/connector/0");
    },
    newDevice(command) {
      this.$router.push("/dashboard/edit/connector/0/device/0");
    },
    newData(command) {
      this.$router.push("/dashboard/edit/connector/0/device/0/data/0");
    },
    newAlarm(command) {
      this.$router.push("/dashboard/edit/connector/0/device/0/data/0/alarm/0");
    },
    importConnectors() {
      this.importEntity = "connector";
    },
    importDevices() {
      this.importEntity = "device";
    },
    importData() {
      this.importEntity = "data";
    },
    importAlarms() {
      this.importEntity = "alarm";
    },
    clearImportEntity() {
      this.importEntity = "";
    },
    massRemove(command) {
      return; // can be implemented at child level
    },
    onLoadNextPage() {
      this.fetchItems(this.next_page || null);
    },
    onMultiColumnSort(columns) {
      this.reset();
      this.sortOrder = columns;
      this.fetchItems();
    },
    // Perform the api fetch
    _fetch(query, url, resolve) {
      let self = this;
      self.$emit("loading", true);
      self.busy = true;
      self.service.fetch(query, url).then((response) => {
        self.busy = false;
        self.$emit("loading", false);
        if (response && typeof response == "object") {
          let items = self.items || [];
          let results = [];
          if ("results" in response && response.results.length) {
            self.maxResult = response.count;
            self.next_page = response.next_page;
            self.prev_page = response.previous_page;
            self.curr_page = url;
            results = response.results;
          } else if (response.length) {
            self.maxResult = response.length;
            results = response;
          }
          // merge current list with results
          results.forEach(function(r) {
            items = items.filter(function(i) {
              return i.id != r.id; // remove if it exists and so only latest version is considered
            });
            items.push(r); // insert;
          });
          if (!this?.items?.length && this.parseItems) {
            items = this.parseItems(items);
            if (response.length) {
              this.maxResult = items.length;
            }
          }
          self.$set(self, "items", items);
          resolve(true);
        } else {
          if (self.prev_page) {
            self.curr_page = self.prev_page;
            self.prev_page = null;
          } else {
            self.curr_page = null;
            resolve(false);
          }
        }
      });
    },
    async fetch(query, url) {
      return new Promise((resolve) => {
        this._fetch(query, url, resolve);
      });
    },
    getResourceById(type, qid) {
      let source = [];
      switch (type) {
        case "connector":
          source = this.$store.getters["dashboard/connectorList"];
          break;
        case "device":
          source = this.$store.getters["dashboard/deviceList"];
          break;
        case "data":
          source = this.$store.getters["dashboard/dataList"];
          break;
      }
      return source.find(({ id }) => id == qid);
    },
    getScreenById(value) {
      return this.screenList.find(({ id }) => parseInt(id) == parseInt(value));
    },
    downloadCSV(connector) {
      if (!this.service || !this?.contract?.id) return;
      var ids = (this.nSelected) ? this.multiSelection.values : (this.$refs?.stbl?.filteredList || []).map(({ id }) => id);
      if (ids.length) {
        var fname = connector
          ? `${this.entity}-connector-${this?.connector?.name || "unknown"}.csv`
          : "connectors.csv";
        this.service.export(
          `contract_id=${this.contract.id}&ids=${ids.join()}`,
          fname
        );
      }
    }
  },
  created() {
    this.multiSelection.key = this.cloneEnabled ? "" : "id";
    this.$root.$emit("controlSidebar:setContent", null);
    this.fetchItems();
  }
};
</script>
