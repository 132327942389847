<template>
  <div class="nav-tabs-custom">
    <ul class="nav nav-tabs pull-right">
      <li :class="isMQTT ? 'active' : ''">
        <a href="#tab-pub" data-toggle="tab">
          <i class="fa fa-globe"></i>
        </a>
      </li>
      <li class="active" v-if="!isMQTT">
        <a href="#tab-pvt" data-toggle="tab">
          <i class="fa fa-key"></i>
        </a>
      </li>
      <li class="pull-left header">
        <i class="fa fa-th"></i> {{ $t("connector_access") }}
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane" id="tab-pub" :class="isMQTT ? 'active' : ''">
        <div class="panel-title">
          <i class="fa fa-dashboard"></i> Dashboard
          <Tooltip :title="$parent.$t('hints.public_dashboard')" />
        </div>
        <FormConnectorPublicTokens
          :connector="connector"
          @loading="$emit('loading', $event)"
        />
      </div>
      <div class="tab-pane active" id="tab-pvt" v-if="!isMQTT">
        <div class="panel-title">
          <i class="fa fa-plug"></i> Tokens
          <Tooltip :title="$parent.$t('hints.token')" />
        </div>
        <FormConnectorPrivateToken
          :connector_id="connector.id"
          @loading="$emit('loading', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { isMQTT } from "@/services/connector.js";
import Tooltip from "@/components/tooltip.vue";
import FormConnectorPrivateToken from "@/components/registration/form-connector-private-token.vue";
import FormConnectorPublicTokens from "@/components/registration/form-connector-public-tokens.vue";
export default {
  name: "FormConnectorTokens",
  components: {
    Tooltip,
    FormConnectorPrivateToken,
    FormConnectorPublicTokens
  },
  props: {
    connector: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  computed: {
    isMQTT() {
      return isMQTT(this.connector);
    }
  }
};
</script>

<style scoped>
.tab-content {
  min-height: 150px;
}
.panel-title {
  margin-bottom: 10px;
  text-align: center;
  background: whitesmoke;
  padding: 10px 0;
  margin: -15px -15px 15px -15px;
  font-size: 12pt;
  border-radius: 5px;
}
</style>
